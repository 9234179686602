<template>
	<div class="operation">
		<div class="operation-T">
			<div class="operation-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="operation">
					<div class="operation1">
						<inputs @selects="selectsValue" :selectShow="true" selectPlace="请选择分类" :options="options"></inputs>
					</div>
					<div class="userManagement-tit-mai1-btn"><!-- @click="clickNew()" -->
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="operation-TR">
				<div class="operation-searchsMai">
					<!-- 右侧内容 -->
				</div>
			</div>
		</div>
		<div class="operation-B">
			<div class="operation2">
				<el-table :data="tableData" style="width: 100%" border size="small">
					<template slot="empty">
						<noData></noData>
					</template>
					<el-table-column prop="Id" label="编号" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Logs_class" label="分类" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Logs_Title" label="标题" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column prop="Logs_Txt" label="详情" :show-overflow-tooltip="true">
					</el-table-column>
					<el-table-column label="时间" :show-overflow-tooltip="true">
						<template slot-scope="scope">
							{{scope.row.CreateTime | formatDate(scope.row.CreateTime)}}
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="operation-pagination">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
				 :current-page="pages.page" :page-size="pages.page_z"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		UserList,
		UserLogsClass
	} from '../../api/systemManagement/operation.js'
	import {formatDate} from '../../utils/Times.js'
	export default {
		data() {
			return {
				total:0,
				pages:{ 
					T_class:'',
					page:1,
					page_z:10
				},
				loading: false,
				multipleSelection: [],
				options: [],
				tableData: []
			}
		},
		filters:{
			 formatDate(time) {
			    let date = new Date(time);
			    return formatDate(date);
			},
		},
		created() {
			this.GetUserLogsClassApi()
		},
		mounted() {
			this.GetUserListApi()
		},
		methods: {
			GetUserListApi(){
				UserList(this.pages).then(res=>{
					////console.log('liebiao',res)
					if(res.data.Code===200 && res.data.Msg==='ok!'){
						this.total = res.data.Data.Num
						this.tableData = res.data.Data.Data
					}
				})
			},
			GetUserLogsClassApi(){
				var that = this
				UserLogsClass({}).then(res=>{
					////console.log('sssd',res)
					if(res.data.Code===200 && res.data.Msg==='ok!'){
						var Arr = res.data.Data
						if(Arr!=null){
							Arr.forEach(function(item){
								var obj = {
									value: '',
									label: ''
								}
								obj.value = item
								obj.label = item
								that.options.push(obj)
							})
							that.options.unshift({
								value: '',
								label: '所有分类'
							})
						}
						
					}
				})
			},
			selectsValue(e){
				if(e==='所有分类'){
					this.pages.T_class = ''
				}else{
					this.pages.T_class = JSON.stringify(e)
				}
				this.pages.page = 1
				this.GetUserListApi()
			},
			handleSizeChange(e) { //每页多少条
				this.pages.page_z = e
				this.GetUserListApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetUserListApi()
			},
		}
	}
</script>

<style lang="scss">
	.el-tooltip__popper{
	  max-width:400px;
	}
	.operation {
		user-select: none;
		.operation-mod {
			.operation-mod-pag {
				margin-top: 14px;
			}
			.operation-mod-search {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0;

				.operation-mod-search-L {
					display: flex;
					align-items: center;

					.operation-mod-search-L1 {
						margin-right: 10px;
					}
				}
			}
		}

		.operation-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;
			.operation {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 20px;
				.userManagement-tit-mai1-btn{
				}
				.operation1{
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.operation-TL {
				display: flex;
				align-items: center;
			}

			.operation-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;

				.operation-searchsMai1 {
					height: 30px;
					display: flex;
					align-items: center;
					color: #F56C6C;
					border-radius: 30px;
					background: #F56C6C;
					color: #fff;
					padding: 0 10px;
					font-size: 12px;
					border: 1px solid #ea6868;
				}

				.operation-searchsMai1q {
					background: #e6a23c;
					border: 1px solid #e6a23c;
					margin-right: 10px;
				}
			}
		}
		.operation-B{
			background: rgba(#fff, 1);
		}
		.operation2 {
			// border-radius: 10px;
			margin: 10px;
			padding: 20px;
		}
		.operation-pagination {
			padding:50px 20px;
			margin: 10px;
			border-radius: 2px;
		}
	}
</style>
