// 系统日志接口
import {
	axios
} from '../../utils/http.js'

const DevApi = {
	UserList: '/v3/System/UserLogsList',//用户日志列表
	UserLogsClass: '/v3/System/UserLogsClass',//用户日志select
}


//用户日志列表
export function UserList(data) {
	return axios({
		url: DevApi.UserList,
		method: 'post',
		data:data
	})
}

//系统日志select
export function UserLogsClass(data) {
	return axios({
		url: DevApi.UserLogsClass,
		method: 'post',
		data:data
	})
}
